import { useRoutes } from "react-router-dom";
import { getCookie } from "../store/Cookie";
// project import
import MainRoutes from "./MainRoutes";
import SignInRoutes from "./SignInRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const hasToken = getCookie("accessToken");

    return useRoutes(hasToken ? [MainRoutes, SignInRoutes] : [SignInRoutes]);
}
