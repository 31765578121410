import MinimalLayout from "../layout/MinimalLayout";
import PasswordChangePage from "../pages/PasswordChange/PasswordChangePage";
import SignInPage from "../pages/SignIn/SignInPage";

const SignInRoutes = {
    path: "/",
    element: <MinimalLayout />,
    children: [
        {
            path: "/",
            element: <SignInPage />,
        },
        {
            path: "/password-change",
            element: <PasswordChangePage />,
        },
    ],
};

export default SignInRoutes;
