import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Box, IconButton, InputAdornment, OutlinedInput, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import PrimaryButton from "../../components/button/PrimaryButton";
import { PasswordChangeFormValues } from "./PasswordChangePage";
import styled from "styled-components";

const EMAIL_INPUT_NAME = "id";
const CURRENT_PASSWORD_INPUT_NAME = "currentPassword";
const NEW_PASSWORD_INPUT_NAME = "newPassword";
const CONFIRM_PASSWORD_INPUT_NAME = "confirmPassword";

interface Props {
    currentPasswordVisible: boolean;
    newPasswordVisible: boolean;
    confirmPasswordVisible: boolean;
    onCurrentPasswordVisibleChange: (currentPasswordVisible: boolean) => void;
    onNewPasswordVisibleChange: (newPasswordVisible: boolean) => void;
    onConfirmPasswordVisibleChange: (confirmPasswordVisible: boolean) => void;
    onPasswordChangeSubmit: (values: PasswordChangeFormValues) => void;
}

const PasswordChangePageTemplate = ({
    currentPasswordVisible,
    newPasswordVisible,
    confirmPasswordVisible,
    onCurrentPasswordVisibleChange,
    onNewPasswordVisibleChange,
    onConfirmPasswordVisibleChange,
    onPasswordChangeSubmit,
}: Props) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100vh"
            pb="100px"
        >
            <Header>비밀번호 변경</Header>
            <Formik
                initialValues={{
                    id: "",
                    currentPassword: "",
                    newPassword: "",
                    confirmPassword: "",
                }}
                onSubmit={onPasswordChangeSubmit}
            >
                {({ values, handleSubmit, setFieldValue }) => {
                    const passwordChangeDisabled = !(
                        values.id &&
                        values.currentPassword &&
                        values.newPassword &&
                        values.confirmPassword
                    );
                    return (
                        <form noValidate onSubmit={handleSubmit}>
                            <Box display="flex" flexDirection="column" width="380px" gap="16px">
                                <InputBox>
                                    <Label>
                                        E-mail<span>*</span>
                                    </Label>
                                    <TextField
                                        name={EMAIL_INPUT_NAME}
                                        type="text"
                                        placeholder="이메일을 입력해 주세요."
                                        value={values.id}
                                        onChange={(e) => setFieldValue(EMAIL_INPUT_NAME, e.target.value)}
                                    />
                                </InputBox>
                                <InputBox>
                                    <Label>
                                        기존 비밀번호<span>*</span>
                                    </Label>
                                    <OutlinedInput
                                        value={values.currentPassword}
                                        name={CURRENT_PASSWORD_INPUT_NAME}
                                        autoComplete="new-password"
                                        type={currentPasswordVisible ? "text" : "password"}
                                        placeholder="기존 비밀번호를 입력해 주세요."
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label={
                                                        currentPasswordVisible
                                                            ? "hide the password"
                                                            : "display the password"
                                                    }
                                                    onClick={() =>
                                                        onCurrentPasswordVisibleChange(
                                                            !currentPasswordVisible
                                                        )
                                                    }
                                                    edge="end"
                                                >
                                                    {currentPasswordVisible ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        onChange={(e) =>
                                            setFieldValue(
                                                CURRENT_PASSWORD_INPUT_NAME,
                                                e.target.value.trim()
                                            )
                                        }
                                    />
                                </InputBox>
                                <InputBox>
                                    <Label>
                                        새 비밀번호<span>*</span>
                                    </Label>
                                    <OutlinedInput
                                        value={values.newPassword}
                                        name={NEW_PASSWORD_INPUT_NAME}
                                        type={newPasswordVisible ? "text" : "password"}
                                        placeholder="새 비밀번호를 입력해 주세요."
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label={
                                                        newPasswordVisible
                                                            ? "hide the password"
                                                            : "display the password"
                                                    }
                                                    onClick={() =>
                                                        onNewPasswordVisibleChange(!newPasswordVisible)
                                                    }
                                                    edge="end"
                                                >
                                                    {newPasswordVisible ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        onChange={(e) =>
                                            setFieldValue(NEW_PASSWORD_INPUT_NAME, e.target.value.trim())
                                        }
                                    />
                                </InputBox>
                                <InputBox>
                                    <Label>
                                        새 비밀번호 확인<span>*</span>
                                    </Label>
                                    <OutlinedInput
                                        value={values.confirmPassword}
                                        name={CONFIRM_PASSWORD_INPUT_NAME}
                                        type={confirmPasswordVisible ? "text" : "password"}
                                        placeholder="새 비밀번호를 한번 더 입력해 주세요."
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label={
                                                        confirmPasswordVisible
                                                            ? "hide the password"
                                                            : "display the password"
                                                    }
                                                    onClick={() =>
                                                        onConfirmPasswordVisibleChange(
                                                            !confirmPasswordVisible
                                                        )
                                                    }
                                                    edge="end"
                                                >
                                                    {confirmPasswordVisible ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        onChange={(e) =>
                                            setFieldValue(
                                                CONFIRM_PASSWORD_INPUT_NAME,
                                                e.target.value.trim()
                                            )
                                        }
                                    />
                                </InputBox>
                                <PrimaryButton
                                    type="submit"
                                    sx={{ borderRadius: "5px", marginTop: "8px" }}
                                    onClick={handleSubmit}
                                    disabled={passwordChangeDisabled}
                                >
                                    비밀번호 변경
                                </PrimaryButton>
                            </Box>
                        </form>
                    );
                }}
            </Formik>
        </Box>
    );
};

export default PasswordChangePageTemplate;

const Header = styled(Typography)`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 40px;
`;

const Label = styled(Typography)`
    display: flex;
    gap: 4px;
    font-size: 15px;
    font-weight: 500;

    span {
        color: #fc2162;
    }
`;

const InputBox = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;
