import React, { useState } from "react";
import PasswordChangePageTemplate from "./PasswordChangePageTemplate";
import { debounce } from "lodash";
import { ChangePasswordRequest, requestChangePassword } from "../../api/authService";
import { useNavigate } from "react-router";
import { useAlert } from "../../context/alert";
import { useMutation } from "react-query";
import { AxiosError } from "axios";
import { APIResponse } from "../../types/response";

export interface PasswordChangeFormValues {
    id: string;
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
}

const PASSWORD_CHANGE_DEBOUNCE_TIME = 300;
const DEFAULT_ERROR_MESSAGE = "비밀번호 변경에 실패했습니다.<br />다시 시도해 주세요.";

const PasswordChangePage = () => {
    const navigate = useNavigate();
    const customAlert = useAlert();
    const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
    const [newPasswordVisible, setNewPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

    const { mutate: changePassword } = useMutation<
        null,
        AxiosError<APIResponse<null>>,
        ChangePasswordRequest
    >("change-password", async (data) => await requestChangePassword(data), {
        onSuccess: async () => {
            await customAlert({
                message: "비밀번호 변경이 완료되었습니다.",
            });
            navigate("/");
        },
        onError: (error) => {
            if (error.response?.status === 400) {
                customAlert({
                    message: error.response?.data?.message ?? DEFAULT_ERROR_MESSAGE,
                });
            } else {
                customAlert({
                    message: DEFAULT_ERROR_MESSAGE,
                });
            }
        },
    });

    const handlePasswordChangeSubmit = debounce((values: PasswordChangeFormValues) => {
        if (values.newPassword !== values.confirmPassword) {
            customAlert({ message: "비밀번호가 일치하지 않습니다.<br />다시 확인해 주세요." });
            return;
        }
        changePassword({
            id: values.id,
            oldPassword: values.currentPassword,
            newPassword: values.newPassword,
        });
    }, PASSWORD_CHANGE_DEBOUNCE_TIME);

    const handleCurrentPasswordVisibleChange = (visible: boolean) => {
        setCurrentPasswordVisible(visible);
    };

    const handleNewPasswordVisibleChange = (visible: boolean) => {
        setNewPasswordVisible(visible);
    };

    const handleConfirmPasswordVisibleChange = (visible: boolean) => {
        setConfirmPasswordVisible(visible);
    };

    return (
        <PasswordChangePageTemplate
            currentPasswordVisible={currentPasswordVisible}
            newPasswordVisible={newPasswordVisible}
            confirmPasswordVisible={confirmPasswordVisible}
            onCurrentPasswordVisibleChange={handleCurrentPasswordVisibleChange}
            onNewPasswordVisibleChange={handleNewPasswordVisibleChange}
            onConfirmPasswordVisibleChange={handleConfirmPasswordVisibleChange}
            onPasswordChangeSubmit={handlePasswordChangeSubmit}
        />
    );
};

export default PasswordChangePage;
