import { lazy } from "react";

// project import
import Loadable from "../components/Loadable";
import MainLayout from "../layout/MainLayout";

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import("../pages/dashboard")));

const ItemsAllSearch = Loadable(lazy(() => import("../pages/items/allSearch")));
const ItemsTagging = Loadable(lazy(() => import("../pages/items/tagging")));
const ItemsSearch = Loadable(lazy(() => import("../pages/items/search")));
const Items3pl = Loadable(lazy(() => import("../pages/items/3pl")));
const ItemsKGSelling = Loadable(lazy(() => import("../pages/items/kgSelling")));
const ItemsReturn = Loadable(lazy(() => import("../pages/items/return")));
const ItemsDonation = Loadable(lazy(() => import("../pages/items/donation")));
const ItemsInbound = Loadable(lazy(() => import("../pages/items/inbound")));
const ItemsDisplayEnd = Loadable(lazy(() => import("../pages/items/displayEnd")));

const BagsSearch = Loadable(lazy(() => import("../pages/bags/search")));
const BagsOrder = Loadable(lazy(() => import("../pages/bags/order")));
const BagsCollection = Loadable(lazy(() => import("../pages/bags/collection")));
const BagsCancel = Loadable(lazy(() => import("../pages/bags/cancel")));

const SellsSearch = Loadable(lazy(() => import("../pages/sells/search")));
const SellsOrder = Loadable(lazy(() => import("../pages/sells/order")));
const ReturnOrder = Loadable(lazy(() => import("../pages/sells/returnOrder")));

const CashChange = Loadable(lazy(() => import("../pages/cash/change")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: "/",
    element: <MainLayout />,
    children: [
        {
            path: "/",
            element: <DashboardDefault />,
        },
        {
            path: "/dashboard",
            element: <DashboardDefault />,
        },
        {
            path: "bags",
            children: [
                {
                    path: "search",
                    element: <BagsSearch />,
                },

                {
                    path: "order",
                    element: <BagsOrder />,
                },
                {
                    path: "ingress",
                    element: <BagsCollection />,
                },
                {
                    path: "cancel",
                    element: <BagsCancel />,
                },
            ],
        },
        {
            path: "items",
            children: [
                {
                    path: "all-search",
                    element: <ItemsAllSearch />,
                },
                {
                    path: "tagging",
                    element: <ItemsTagging />,
                },
                {
                    path: "search",
                    element: <ItemsSearch />,
                },
                {
                    path: "3pl",
                    element: <Items3pl />,
                },
                {
                    path: "kg-selling",
                    element: <ItemsKGSelling />,
                },
                {
                    path: "return",
                    element: <ItemsReturn />,
                },
                {
                    path: "donation",
                    element: <ItemsDonation />,
                },
                {
                    path: "inbound",
                    element: <ItemsInbound />,
                },
                {
                    path: "display-end",
                    element: <ItemsDisplayEnd />,
                },
            ],
        },
        {
            path: "sells",
            children: [
                {
                    path: "search",
                    element: <SellsSearch />,
                },
                {
                    path: "order",
                    element: <SellsOrder />,
                },
                {
                    path: "refund",
                    element: <ReturnOrder />,
                },
            ],
        },
        {
            path: "cash",
            children: [
                {
                    path: "change",
                    element: <CashChange />,
                },
            ],
        },
    ],
};

export default MainRoutes;
