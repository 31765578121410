import { Box, IconButton, InputAdornment, OutlinedInput, TextField, Typography } from "@mui/material";
import CharanCoreLogo from "../../assets/images/charan_lockup_core.svg";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Google from "../../assets/images/icons/google.svg";
import { Formik } from "formik";
import PrimaryButton from "../../components/button/PrimaryButton";
import { SignInRequest } from "../../api/authService";
import styled from "styled-components";

interface Props {
    passwordVisible: boolean;
    onSignInSubmit: (values: SignInRequest) => void;
    onPasswordVisibleChange: (passwordVisible: boolean) => void;
    onGoogleSignInButtonClick: () => void;
    onPasswordChangeButtonClick: () => void;
}

const SignInPageTemplate = ({
    passwordVisible,
    onSignInSubmit,
    onPasswordVisibleChange,
    onGoogleSignInButtonClick,
    onPasswordChangeButtonClick,
}: Props) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100vh"
            pb="100px"
        >
            <img src={CharanCoreLogo} style={{ width: "120px", marginBottom: "48px" }} />
            <Box display="flex" flexDirection="column">
                <Formik
                    initialValues={{
                        id: "",
                        password: "",
                    }}
                    onSubmit={onSignInSubmit}
                >
                    {({ values, handleSubmit, handleChange }) => {
                        return (
                            <form noValidate onSubmit={handleSubmit}>
                                <Box display="flex" flexDirection="column" width="380px" gap="8px">
                                    <TextField
                                        name="id"
                                        type="email"
                                        value={values.id}
                                        placeholder="이메일을 입력해 주세요."
                                        onChange={handleChange}
                                    />
                                    <OutlinedInput
                                        value={values.password}
                                        name="password"
                                        type={passwordVisible ? "text" : "password"}
                                        placeholder="비밀번호를 입력해 주세요."
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label={
                                                        passwordVisible
                                                            ? "hide the password"
                                                            : "display the password"
                                                    }
                                                    onClick={() =>
                                                        onPasswordVisibleChange(!passwordVisible)
                                                    }
                                                    edge="end"
                                                >
                                                    {passwordVisible ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        onChange={handleChange}
                                    />
                                    <PrimaryButton
                                        type="submit"
                                        sx={{ borderRadius: "5px", marginTop: "8px" }}
                                        onClick={handleSubmit}
                                    >
                                        로그인
                                    </PrimaryButton>
                                </Box>
                            </form>
                        );
                    }}
                </Formik>
                <PasswordChangeButton onClick={onPasswordChangeButtonClick}>
                    비밀번호 변경
                </PasswordChangeButton>
                <PrimaryButton
                    themecolor="white"
                    sx={{ borderRadius: "5px", color: "#202020" }}
                    onClick={onGoogleSignInButtonClick}
                >
                    <img src={Google} style={{ marginRight: "12px" }} />
                    GOOGLE
                </PrimaryButton>
            </Box>
        </Box>
    );
};

export default SignInPageTemplate;

const PasswordChangeButton = styled(Typography)`
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    margin: 16px 0px 64px;
    cursor: pointer;
`;
