import { useState } from "react";
import SignInPageTemplate from "./SignInPageTemplate";
import { debounce } from "lodash";
import { useMutation } from "react-query";
import {
    SignInResponse,
    SignInRequest,
    requestSignIn,
    handleSignInSuccess,
    OAuthSignInRequest,
    requestOAuthSignIn,
} from "../../api/authService";
import { useNavigate } from "react-router";
import { useAlert } from "../../context/alert";
import { useGoogleLogin } from "@react-oauth/google";

const SIGN_IN_DEBOUNCE_TIME = 300;

const SignInPage = () => {
    const navigate = useNavigate();
    const customAlert = useAlert();
    const [passwordVisible, setPasswordVisible] = useState(false);

    const { mutate: signIn } = useMutation<SignInResponse, unknown, SignInRequest>(
        "sign-in",
        async (data) => await requestSignIn(data),
        {
            onSuccess: (response) => {
                handleSignInSuccess(response);
                navigate("/");
            },
            onError: () => {
                customAlert({
                    message: "아이디 또는 비밀번호가 맞지 않습니다.<br/>다시 확인해 주세요.",
                });
            },
        }
    );

    const { mutate: oAuthSignIn } = useMutation<SignInResponse, unknown, OAuthSignInRequest>(
        "o-auth-sign-in",
        async (data) => await requestOAuthSignIn(data),
        {
            onSuccess: (response) => {
                handleSignInSuccess(response);
                navigate("/");
            },
            onError: () => {
                customAlert({ message: "로그인중 오류가 발생했습니다." });
            },
        }
    );

    const handleGoogleSignIn = useGoogleLogin({
        onSuccess: (response) => {
            if (response && response.access_token.length) {
                oAuthSignIn({ provider: "google", oauthToken: response.access_token });
            } else {
                customAlert({ message: "구글 액세스 토큰에 문제가 발생했습니다." });
            }
        },
    });

    const handleSignIn = debounce((data: SignInRequest) => {
        if (data.id === "" || data.password === "") {
            customAlert({
                message: "아이디 또는 비밀번호를 입력해주세요.",
            });
            return;
        }
        signIn(data);
    }, SIGN_IN_DEBOUNCE_TIME);

    const handlePasswordChangeButtonClick = () => {
        navigate("/password-change");
    };

    return (
        <SignInPageTemplate
            passwordVisible={passwordVisible}
            onSignInSubmit={handleSignIn}
            onPasswordVisibleChange={setPasswordVisible}
            onGoogleSignInButtonClick={handleGoogleSignIn}
            onPasswordChangeButtonClick={handlePasswordChangeButtonClick}
        />
    );
};

export default SignInPage;
